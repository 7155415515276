import { ClerkApp } from "@clerk/remix";
import { rootAuthLoader } from "@clerk/remix/ssr.server";
import { init, isInitialized } from "@fullstory/browser";
import {
  json,
  type LinkDescriptor,
  type LinksFunction,
  type MetaFunction,
  type SerializeFrom,
} from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import type { ExternalScriptsFunction } from "remix-utils/external-scripts";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Toaster } from "~/components/ui/toaster";
import tailwindStylesheetUrl from "~/tailwind.css?url";
import { getPublicEnvs } from "~/utils/env.server";
import { useGoogleTagManager } from "~/utils/useGoogleTagManager";
import { GeneralErrorBoundary } from "./components/error-boundary";
import { AddBusinessProvider } from "./routes/business+/_menuRoutes+/add/context/addBusinessProvider";

declare global {
  interface Window {
    Weglot: any;
  }
}

export const links: LinksFunction = () => {
  return [
    { rel: "preload", href: tailwindStylesheetUrl, as: "style" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/apple-touch-icon.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/favicon-16x16.png",
    },
    { rel: "manifest", href: "/site.webmanifest" },
    { rel: "stylesheet", href: tailwindStylesheetUrl },
    // NOTE: Not sure why typescript isn't picking up on the filter here so it is explicitly cast
  ].filter(Boolean) as LinkDescriptor[];
};

export const meta: MetaFunction = () => {
  return [
    { title: "Hansa" },
    { name: "description", content: "Find your business, fix your data" },
  ];
};

export async function loader(args: any) {
  const ENV = getPublicEnvs();
  const stringEnv = JSON.stringify(ENV);

  return rootAuthLoader(args, async () =>
    json({
      ENV,
      stringEnv,
    }),
  );
}

// Load external Lendica script
let scripts: ExternalScriptsFunction<SerializeFrom<typeof loader>> = () => {
  const scriptToLoad = "https://cdn.weglot.com/weglot.min.js";
  const lendflowScript = "https://iw.lendflow.com/js/lendflow-loader.js";

  return [
    {
      src: scriptToLoad,
      async: true,
      id: "weglot-script",
    },
    {
      src: lendflowScript,
      async: true,
      id: "lendflow-script",
    },
  ];
};

export let handle = {
  scripts,
};

function App() {
  // Get the locale from the loader
  let loaderData = useLoaderData<typeof loader>();
  let { ENV, stringEnv } = loaderData;

  // Insert Google Tag Manager script tag
  useGoogleTagManager("GTM-PLW3PGJ", ENV.MODE);

  React.useEffect(() => {
    const enabled = ENV.MODE === "production";

    if (enabled && !isInitialized()) {
      init({ orgId: "o-1RAW5J-na1", devMode: !enabled }, () =>
        console.log("FullStory is ready!"),
      );
    }
  }, [ENV.MODE]);

  React.useEffect(() => {
    if (typeof window !== "undefined" && window.Weglot) {
      window.Weglot.initialize({
        api_key: "wg_b459904410b8c8503fac38acdb17e2e02",
      });
    }
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={ENV.CAPTCHA_SITE_KEY}
      scriptProps={{
        async: false,
        defer: true,
        appendTo: "head",
        nonce: undefined,
      }}
    >
      <html className="h-full">
        <head>
          <Meta />
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Links />
        </head>

        <body
          id="root-content"
          className="m-0 flex min-h-screen w-full flex-col justify-between bg-white p-0"
        >
          <AddBusinessProvider>
            <Outlet />
            <script
              dangerouslySetInnerHTML={{
                __html: `window.ENV = ${stringEnv}`,
              }}
            />

            <Toaster />
            <ScrollRestoration />
            <ExternalScripts />
            <Scripts />
          </AddBusinessProvider>
          {ENV.MODE === "staging" ||
            (ENV.MODE === "production" && (
              <noscript>
                <iframe
                  title="gtm-no-script"
                  src="https://www.googletagmanager.com/ns.html?id=GTM-PLW3PGJ"
                  height="0"
                  width="0"
                  className="hidden invisible"
                ></iframe>
              </noscript>
            ))}
        </body>
      </html>
    </GoogleReCaptchaProvider>
  );
}

export function ErrorBoundary() {
  return (
    <html className="h-full">
      <head>
        <Meta />
        <Links />
      </head>
      <body className="h-full w-full bg-white">
        <GeneralErrorBoundary />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default Sentry.withSentry(ClerkApp(App));
